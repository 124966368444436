@import "../node_modules/font-awesome/scss/font-awesome.scss";
@import "~font-awesome/css/font-awesome.css";

// table {
//     display: flex;
//     flex-flow: column;
//     width: 100%;
// }

tbody {
    display:block;
    // max-height:500px;
    overflow-y:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
thead {
    width: calc( 100% - 1em )
} 
::ng-deep tbody  tr.ng2-smart-row.selected
{ background:lightblue !important; border:1px solid blue;}

@media (min-width: 650px) and (max-width: 20000px) {
    .is-not-mobile{
     display: inline;
    }
    .is-mobile{
     display: none;
    }
  }
 
  @media (min-width: 5px) and (max-width: 650px) {
   .is-not-mobile{
    display: none;
   }
   .is-mobile{
     display: inline;
   }
 }
 
 .mob-pro{
   font-weight:800;
 }
 
 .mob-pr{
   padding:5px;
 }
 
 .mob-par{
   border-color: black;
   border-width: 2px;
   border-style: solid;
 }





 zxing-scanner {
  max-width: 100%;
}

.results {

  position: sticky;
  bottom: 0;
  padding: 15px;
  background: #02c3c3;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  small {
    font-weight: bolder;
    display: block;
    font-size: 60%;
    margin: -.6em 0 0 0;
  }

  button {
    background: none;
    border: none;
    line-height: 1;
    font-weight: bolder;
    font-size: 120%;
    float: right;
    color: #fff;
    $mg: 8px;
    margin: (-$mg) (-$mg) (-$mg) $mg;
  }
}

h2 {
  padding: 0 16px;
}
